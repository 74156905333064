import { CommandManager } from "./CommandManager";
import RoomManager from "../RoomManager/RoomManager";
import { RoomCommand } from "../../models/commands/RoomCommand";
import { appModel } from "../../../models/AppModel";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";

export class RoomCommandManager extends CommandManager<RoomManager, RoomCommand> {
  private calculatedCostMarker: Map<string, number>;

  public updateCostStamp(): void {
    this.calculatedCostMarker = [...this.scopes.entries()].reduce((map, scope) => {
      map.set(scope[0], scope[1].undos.length);
      return map;
    }, new Map<string, number>());
  }

  public isMarkerOutdated(): boolean {
    return [...this.calculatedCostMarker.entries()].some(cm => this.scopes.get(cm[0]).undos.length !== cm[1]);
  }

  public apply(command: RoomCommand): void {
    super.apply(command);
    this.validate();
  }

  public add(command: RoomCommand): void {
    if (this.calculatedCostMarker && !this.calculatedCostMarker.has(appModel.activeFloor.id)) {
      this.calculatedCostMarker.set(appModel.activeFloor.id, 0);
    } else if (
      this.calculatedCostMarker?.has(appModel.activeFloor.id) &&
      this.activeScope.undos.length < this.calculatedCostMarker.get(appModel.activeFloor.id)
    ) {
      this.calculatedCostMarker = null;
    }
    super.add(command);
  }

  public undo(): RoomCommand {
    const command = super.undo();
    if (command) {
      this.validate();
      if (this.calculatedCostMarker) {
        appModel.activeCorePlan.setIsCostOutdated(this.isMarkerOutdated());
      }
    }

    return command;
  }

  public redo(): RoomCommand {
    const command = super.redo();
    if (command) {
      this.validate();
      if (this.calculatedCostMarker) {
        appModel.activeCorePlan.setIsCostOutdated(this.isMarkerOutdated());
      }
    }

    return command;
  }

  public validate(): void {
    const activeSoFloor = this.manager.getActiveSoFloor();
    const selectedSoRooms = this.manager.getCorePlanSelectedSoRooms();

    this.manager.floorDimensionTool.setRooms(activeSoFloor.children, true);
    this.manager.selectedRoomsDimensionTool.setRooms(selectedSoRooms, false);
    WallAnalysisUtils.regenerateRoomsWalls(activeSoFloor.children);
    this.manager.updateSegmentsCache();
    this.manager.updateRoofContour();
    this.manager.updateObsoleteRoomsHighlighting();
    this.manager.checkRoomsOverlapping();
    appModel.activeCorePlan.setIsCostOutdated(true);
    this.manager.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.manager.getSoFloorsRoot());
    this.manager.checkBlockedDoors();
    this.manager.updateCladding();
    this.manager.validateCladding();
    this.manager.performValidation();
    this.manager.shortWallSegmentsTool.validateShortSegments();
  }

  public clearScopes(scopeIds?: string[]) {
    super.clearScopes(scopeIds);
    this.calculatedCostMarker?.clear();
  }
}
